import {
  compose, reduce, isEmpty, match, find,
  addIndex, prop, propEq, findIndex,
} from 'ramda';

export const reduceIndexed = addIndex(reduce);

export const invalidField = compose(
  isEmpty,
  match,
);

export const getFieldErrorMessage = (fields, fieldName) => prop(
  'validationMessage',
  find(
    propEq('name', fieldName),
    fields,
  ),
);

export const getFormFieldByName = (name, formFields) => find(
  propEq('name', name),
  formFields,
);

export const getActiveStepIndex = findIndex(prop('active'));

export const validateAddress = (value) => {
  if (value.postcode && value.postcode !== '') {
    return true;
  }

  return false;
};

export default {
  validateAddress,
};

export const isValid = values => ![
  values.title,
  values.firstName,
  values.lastName,
  values.email,
  values.telephone.raw,
  values.terms,
].includes('');
