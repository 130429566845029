import React from 'react';
import PropTypes from 'prop-types';

import InlineCheckbox from '../InlineCheckbox';

const InlineCheckBoxes = (props) => {
  const {
    name, value, onChange, inputs, selectAll,
  } = props;

  const allSelected = Object.keys(value).every(key => value[key] !== '');

  const handleChange = (_name, _val) => onChange(name, { ...value, [_name]: _val });

  const handleSelectAll = () => {
    const newVal = { ...value };
    Object.keys(newVal).forEach((key) => {
      newVal[key] = allSelected ? '' : 'true';
    });
    return onChange(name, newVal);
  };


  return (
    <div>
      {
        selectAll.visible && (
          <InlineCheckbox
            onChange={handleSelectAll}
            name={`${name}-select-all`}
            value={allSelected ? 'true' : ''}
            label={selectAll.label}
          />
        )
      }
      {inputs.map(item => (
        <React.Fragment key={item.name}>
          <InlineCheckbox
            onChange={handleChange}
            name={item.name}
            value={value[item.name] || ''}
            label={item.label}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

InlineCheckBoxes.defaultProps = {
  selectAll: {
    visible: false,
    label: 'Select all',
  },
};

InlineCheckBoxes.propTypes = {
  name: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOf(['', 'true']),
    }),
  ).isRequired,
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.shape({
    visible: PropTypes.bool,
    label: PropTypes.string,
  }),
};

export default InlineCheckBoxes;
