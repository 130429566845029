import { emailRegex, emailAdvancedValidation } from '@ropto/form-input-email';
import TextInput from './Inputs/TextInput';
import EmailInput from './Inputs/EmailInput';
import InlineCheckbox from './Inputs/InlineCheckbox';
import InlineCheckBoxes from './Inputs/InlineCheckBoxes';
import {
  submitInterestToMds,
  submitLeadToMds, submitToLds,
} from './lib/submitFunctions';

const NON_EMPTY_PATTERN = /[^$]$/;
const submitFunction = async (args) => {
  await submitToLds(args);

  const { leadId } = await submitLeadToMds(args);
  await submitInterestToMds({ leadId, ...args });
};

export default step => [
  {
    active: step === 'signUp',
    path: '/',
    stepNr: 1,
    inputs: [
      {
        element: TextInput,
        label: 'First name:',
        name: 'firstName',
        boundTo: 'firstName',
        validationRegex: NON_EMPTY_PATTERN,
        validationMessage: 'Please enter your first name',
      },
      {
        element: TextInput,
        label: 'Last name:',
        name: 'lastName',
        boundTo: 'lastName',
        validationMessage: 'Please enter your last name',
        validationRegex: NON_EMPTY_PATTERN,
      },
      {
        element: EmailInput,
        label: 'Email address:',
        name: 'email',
        boundTo: 'email',
        validationMessage: 'Please enter a valid email address',
        validationRegex: emailRegex,
        validationFunction: emailAdvancedValidation,
      },
    ],
  },
  {
    active: step === 'signUp2',
    path: 'sign-up2',
    stepNr: 2,
    submitFunction,
    inputs: [
      {
        element: InlineCheckBoxes,
        boundTo: 'marketingPreferences',
        name: 'marketingPreferences',
        sessionSafe: true,
        selectAll: {
          visible: true,
          label: 'I want to hear about everything\n',
        },
        inputs: [
          {
            element: InlineCheckbox,
            boundTo: 'latest_claims',
            name: 'latest_claims',
            label: 'The latest claims\n',
          },
          {
            element: InlineCheckbox,
            boundTo: 'utilities',
            name: 'utilities',
            label: 'Utility offers, such as broadband and energy\n',
          },
          {
            element: InlineCheckbox,
            boundTo: 'insurance',
            name: 'insurance',
            label: 'Insurance',
          },
          {
            element: InlineCheckbox,
            boundTo: 'credit_products',
            name: 'credit_products',
            label: 'Credit products',
          },
          {
            element: InlineCheckbox,
            boundTo: 'debt_help',
            name: 'debt_help',
            label: 'Debt help',
          },
        ],
      },

    ],
  },
];
