import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';

const EmailInput = (props) => {
  const {
    name, label, value, onChange, error,
  } = props;

  const classes = [
    'input-leadgen__text',
    `${value !== '' ? 'input-leadgen__text--completed' : null}`,
    `${error ? 'input-leadgen__text--error' : null}`,
  ];

  return (
    <div className="input-leadgen__wrapper" id={`${name}Group`}>
      <div className="input-leadgen__container">
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={`input-leadgen__label input-leadgen__label--float ${value !== '' && 'input-leadgen__label--float--active'}`} htmlFor={name}>{label}</label>
        <input
          className={classes.join(' ')}
          name={name}
          type="email"
          value={value}
          onChange={e => onChange(e.target.name, e.target.value)}
          id={name}
        />
      </div>
      {error && <p className="input-leadgen__error">{error}</p>}
    </div>
  );
};

EmailInput.defaultProps = {
  error: null,
};

EmailInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default EmailInput;
