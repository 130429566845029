import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Details = ({
  children, heading, paragraph, preferences, thankYou,
}) => (
  <section className="details">
    <div className="details__container">
      <div className={`details__content ${thankYou && 'details__content--thankYou'} ${preferences && 'details__content--preferences'}`}>
        <h3 className="details__header">
          {heading}
        </h3>
        <p className="details__para">
          {paragraph}
        </p>
      </div>
      <div className="details__wrapper">
        <div className="details__column details__column--form">
          {children}
        </div>
      </div>
    </div>
  </section>
);

Details.defaultProps = {
  thankYou: false,
  preferences: false,
};

Details.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  thankYou: PropTypes.bool,
  preferences: PropTypes.bool,
};

export default Details;
