import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Details from '../components/Details';
import LeadForm from '../components/LeadForm';


const SignUp = ({ location, data }) => (
  <Layout location={location} cta={false} short grey>
    <SEO title="Sign up" />
    <Details
      location={location}
      heading="FREE Newsletter"
      paragraph="Receive regular notifications on a wide range of financial products. Update your preferences at any time."
    >
      <LeadForm
        location={location}
        clientId={data.site.siteMetadata.clientId}
        productId={data.site.siteMetadata.productId}
        step="signUp"
        showProgress
        showSSL
        noBorder
        title="Sign up here"
        kicker="To receive unmissable offers and money&#8209;saving tips straight to your inbox."
      />
    </Details>
  </Layout>
);

SignUp.propTypes = {
  location: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
};

export default SignUp;

export const query = graphql`
  query {
    site {
      siteMetadata {
        clientId,
        productId,
      }
    }
  }
`;
