import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import line from '../progress-border.svg';
import mmbArrow from '../mmb-arrow.svg';

const Progress = ({ activeStep }) => (
  <div className="progress">
    <div className="progress__border">
      <h3 className="progress__step-number">
        {activeStep}
        of 2
      </h3>
      <img src={line} alt="Border" />
    </div>

    <div className="progress__steps">
      <div className={`progress__step ${activeStep === 1 || activeStep === 2 ? 'progress__step--active' : ''}`}>
        <span>
          <img src={mmbArrow} alt="Arrow" />
        </span>
      </div>
      <div className={`progress__step ${activeStep === 2 && 'progress__step--active'}`}>
        <span>
          <img src={mmbArrow} alt="Arrow" />
        </span>
      </div>
    </div>
  </div>
);


Progress.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default Progress;
