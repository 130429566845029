import { submitLead } from '@ropto/client-library';

export function submitToLds(values) {
  const session = window.roptoSession.getSession();
  return submitLead(values, session).then(res => res.json());
}

export function submitLeadToMds(values) {
  const session = window.roptoSession.getSession();

  if (process.env.NODE_ENV === 'development') {
    console.log({ ...session, ...values });
    return Promise.resolve({ leadId: 'abc' });
  }

  return fetch(process.env.MDS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...session,
      ...values,
    }),
  }).then(res => res.json());
}

export function submitInterestToMds(values) {
  const { leadId, productId, marketingPreferences } = values;

  const mappedMarketingPreferences = Object.keys(marketingPreferences).map(key => (
    {
      status: Boolean(marketingPreferences[key]),
      type: 'hard',
      interest: key,
    }
  ));

  const payload = { productId, optIns: mappedMarketingPreferences };
  if (process.env.NODE_ENV === 'development') {
    console.log({ ...payload });
    return Promise.resolve({});
  }

  return fetch(`${process.env.MDS_URL}/${leadId}/interests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
    }),
  }).then(res => res.json());
}
