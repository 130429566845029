import React from 'react';
import PropTypes from 'prop-types';

const InlineCheckbox = (props) => {
  const {
    name, label, value, onChange, error,
  } = props;

  const handleChange = () => onChange(name, value === '' ? 'true' : '');

  const classes = [
    'input-leadgen__checkbox',
    `${value !== '' ? 'input-leadgen__checkbox--checked' : null}`,
    `${error ? 'input-leadgen__checkbox--error' : null}`,
  ];

  return (
    <div className="input" id={`${name}Group`}>
      <input
        className={classes.join(' ')}
        name={name}
        type="checkbox"
        value={value}
        onChange={handleChange}
        id={name}
        checked={value !== ''}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="input-leadgen__label-inline" htmlFor={name} dangerouslySetInnerHTML={{ __html: label }} />
      {error && <p className="input-leadgen__error">{error}</p>}
    </div>
  );
};

InlineCheckbox.defaultProps = {
  error: null,
  label: null,
};

InlineCheckbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default InlineCheckbox;
