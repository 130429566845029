import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  reduce, equals, isEmpty, filter, and, not, has, split, assocPath, path, find, prop, length,
} from 'ramda';
import {
  reduceIndexed, getFieldErrorMessage, invalidField, getFormFieldByName,
  getActiveStepIndex,
} from './helpers';

import Button from '../Button';
import Progress from './Inputs/Progress';

import ssl from '../../images/sslSecure.svg';
import './styles.scss';
import config from './config';
import { setSessionStorage, getSessionStorage } from '../../lib/roptoClientLibrary';

const LOADING = true;
const DISABLED = true;
const ENABLED = false;

class LeadForm extends Component {
  constructor(props) {
    super(props);

    const {
      clientId, productId, step,
    } = props;

    const steps = config(step);

    this.state = {
      status: DISABLED,
      loadingStatus: '',
      steps,
      values: {
        clientId,
        productId,
        firstName: '',
        lastName: '',
        email: '',
        marketingPreferences: {
          latest_claims: '',
          utilities: '',
          insurance: '',
          credit_products: '',
          debt_help: '',
        },
        optIn: {
          sms: false,
          telephone: false,
          post: false,
          email: true,
        },
      },
      errors: {},
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const prevVals = location?.state?.values || getSessionStorage('values') || {};

    const { values: defaultValues } = this.state;

    this.setState({ values: { ...defaultValues, ...prevVals } });
  }

  onFieldChange(fieldName, value) {
    const { values, steps } = this.state;
    const currentActive = getActiveStepIndex(steps);
    const formField = getFormFieldByName(fieldName, steps[currentActive].inputs);
    const boundToPath = split('.', formField.boundTo);
    const updatedValues = assocPath(boundToPath, value, values);
    let newState = DISABLED;
    const marketingPref = updatedValues.marketingPreferences;

    if (currentActive === 0) {
      if (updatedValues.firstName !== '' && updatedValues.lastName !== '' && updatedValues.email !== '') {
        newState = ENABLED;
      }
    }

    if (currentActive === 1) {
      if (Object.keys(marketingPref).filter(key => marketingPref[key] !== '').length) {
        newState = ENABLED;
      }
    }

    this.setState({
      status: newState,
      values: updatedValues,
    });
  }

  onSubmit() {
    const { values, errors, steps } = this.state;
    const { location: { search } } = this.props;

    if (not(equals(errors, {}))) {
      this.setState({
        status: DISABLED,
      });
    } else {
      const currentActiveIndex = getActiveStepIndex(steps);
      const currentActive = steps[currentActiveIndex];

      const currentStep = steps[currentActiveIndex];
      const { inputs: currentStepInputs } = currentStep;
      const fillteredInputs = currentStepInputs.filter(input => 'sessionSafe' in input);

      if (fillteredInputs.length) {
        if (window.roptoSession) {
          fillteredInputs.forEach((input) => {
            window.roptoSession.sendSessionEvent({
              action: 'ANSWER',
              label: input.name,
              value: values[input.name],
            });
          });
        }
      }

      if (typeof currentActive.submitFunction === 'function') {
        currentActive.submitFunction(values).then((response) => {
          if (currentActiveIndex >= (length(steps) - 1)) {
            navigate(
              `/thankyou${search || ''}`,
            );
          } else {
            setSessionStorage('values', values);
            navigate(
              `/${steps[currentActiveIndex + 1].path}/${search}`,
              {
                state: {
                  values: { ...values, ...response },
                },
              },
            );
          }
        });
      } else {
        setSessionStorage('values', values);
        navigate(`/${steps[currentActiveIndex + 1].path}/${search}`, {
          state: { values },
        });
      }
    }
  }

  advancedValidation = async (fields, values, errors) => {
    let validatedFormValues = values;

    // Create an array of Promises that each validate one field.
    const { fieldNames, promises } = reduce(
      (acc, field) => {
        const fieldValue = path(split('.', field.boundTo), values);
        return {
          fieldNames: [
            ...acc.fieldNames,
            field.name,
          ],
          promises: [
            ...acc.promises,
            field.validationFunction(fieldValue, field.validationArgs || null),
          ],
        };
      },
      {
        fieldNames: [],
        promises: [],
      },
      fields,
    );

    // Run all validation promises, returning an object containing error
    // messages keyed by the field name.
    const formErrors = await Promise.all(promises)
      .then(
        results => reduceIndexed(
          (acc, fieldName, i) => {
            if (typeof results[i] === 'boolean') {
              if (results[i]) {
                return acc;
              }
            }

            if (results[i].valid) {
              // If the validation function supplied an replacement value for
              // the field, capture it here.
              if (results[i].updatedValue) {
                validatedFormValues = {
                  ...validatedFormValues,
                  [fieldName]: results[i].updatedValue,
                };
              }
              return acc;
            }

            return ({ ...acc, [fieldName]: getFieldErrorMessage(fields, fieldName) });
          },
          errors,
          fieldNames,
        ),
      );

    // Return the errors and the formValues with any replacement values from the
    // validation functions.
    return {
      formErrors,
      validatedFormValues,
    };
  };

  basicValidation = (fields, values, errors) => reduce(
    (acc, field) => {
      if (has('validationRegex', field)) {
        const fieldValue = path(split('.', field.boundTo), values);
        if (invalidField(field.validationRegex, fieldValue)) {
          return {
            ...acc,
            [field.name]: field.validationMessage,
          };
        }
      }
      return acc;
    },
    errors,
    fields,
  );

  async validate() {
    const { values, steps } = this.state;
    const currentActive = getActiveStepIndex(steps);
    const { inputs } = steps[currentActive];

    let errors = {};
    let updatedFormValues = values;
    errors = this.basicValidation(inputs, values, errors);

    // Perform additional valdiation on any field that both:
    // - passed basic validation (does not have a key in `errros`), and
    // - has a `validationFunctionProperty`
    const advancedValidationFields = filter(
      f => and(
        not(has(f.name, errors)),
        has('validationFunction', f),
      ),
      inputs,
    );

    if (not(isEmpty(advancedValidationFields))) {
      const validationResponse = await this.advancedValidation(
        advancedValidationFields,
        values,
        errors,
      );
      errors = validationResponse.formErrors;
      updatedFormValues = validationResponse.validatedFormValues;
    }

    this.setState({
      errors,
      values: updatedFormValues,
    }, () => {
      this.onSubmit();
    });
  }

  async handleSubmit() {
    this.setState({
      loadingStatus: LOADING,
    }, () => {
      this.validate();
    });
  }

  render() {
    const {
      steps, values, errors, status, loadingStatus,
    } = this.state;
    const {
      showProgress, showSSL, location, title, kicker, noBorder,
    } = this.props;

    const activeStep = find(prop('active'))(steps);

    const elements = activeStep.inputs.map((item) => {
      const {
        name,
        placeholder,
        newValue,
        label,
        items,
        instruction,
        element: Element,
        ...rest
      } = item;
      return (
        <Element
          key={name}
          name={name}
          placeholder={placeholder}
          value={values[name]}
          error={errors[name]}
          newValue={newValue}
          label={label}
          onChange={this.onFieldChange}
          items={items}
          instruction={instruction}
          {...rest}
        />
      );
    });

    const {
      stepNr,
    } = activeStep;
    return (
      <section className={`lead-form ${noBorder ? 'lead-form--no-border' : ''}`} id="leadForm">
        <div>
          {
            showProgress && (
              <Progress activeStep={stepNr} />
            )
          }
          <div className="lead-form__wrapper">
            <h2 className="lead-form__title">
              {title}
            </h2>
            <p className="lead-form__kicker">
              {kicker}
            </p>
            {elements}
            <div className="lead-form__submit">
              <Button
                disabled={status}
                onClick={this.handleSubmit}
                loading={loadingStatus === LOADING}
              >
                {stepNr === 1 ? 'Continue >' : 'Submit >'}
              </Button>
            </div>
          </div>

          <div className="lead-form__disclaimer-wrapper">
            {stepNr === 2 && (
              <p className="lead-form__disclaimer">
                We will handle your personal information in accordance with our
                {' '}
                <a href={`${process.env.POLICY_BASE_URL || ''}/privacy-policy/${location.search}`} rel="noopener noreferrer" target="_blank">
                  Privacy Policy
                </a>
                . By clicking “Submit” above you agree for us to contact you via
                email about the topics you have selected.
              </p>
            )}
            {showSSL && (
              <img src={ssl} alt="SSL Secure" />
            )}
          </div>
        </div>
      </section>
    );
  }
}

LeadForm.defaultProps = {
  showProgress: false,
  showSSL: true,
  title: '',
  kicker: '',
  noBorder: false,
};

LeadForm.propTypes = {
  location: PropTypes.shape().isRequired,
  clientId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
  showSSL: PropTypes.bool,
  title: PropTypes.string,
  kicker: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default LeadForm;
