import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';

const TextInput = (props) => {
  const {
    name, label, value, onChange, error, instruction,
  } = props;

  const classes = [
    'input-leadgen__text',
    `${value !== '' ? 'input-leadgen__text--completed' : null}`,
    `${error ? 'input-leadgen__text--error' : null}`,
  ];

  return (
    <>
      <h2 className="input-leadgen__instruction">{instruction}</h2>
      <div className="input-leadgen__wrapper" id={`${name}Group`}>
        <div className="input-leadgen__container">
          { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={`input-leadgen__label input-leadgen__label--float ${value !== '' && 'input-leadgen__label--float--active'}`} htmlFor={name}>{label}</label>
          <input
            className={classes.join(' ')}
            name={name}
            type="text"
            value={value}
            onChange={e => onChange(e.target.name, e.target.value)}
            id={name}
          />
        </div>
        {error && <p className="input-leadgen__error">{error}</p>}
      </div>
    </>
  );
};

TextInput.defaultProps = {
  error: null,
  instruction: null,
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  instruction: PropTypes.string,
};

export default TextInput;
